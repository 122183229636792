.login-modal-form {
    .modal-content {
        padding-top: 20px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

// .modal-dialog {
//   max-width: 385px;
// }

.modal-content {
    border-radius: 5px;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 40px 0 0;
    text-align: center;
    background-color: $color-white;
    color: $color-text;
    max-height: 90vh;

    .modal__header {
        border-bottom: 1px solid $color-light-gray;
        background-color: $color-white;
    }

    .modal__body {
        overflow-y: scroll;
        &::-webkit-scrollbar {
            border-bottom-right-radius: 5px;
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $color-primary;
            width: 10px;
            border: 2px solid transparent;
            background-clip: content-box;
			
        }
        &::-webkit-scrollbar-track {
            border-bottom-right-radius: 5px;
            background-color: $color-dusty-white;
			
        }
    }
}

.modal__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 14px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
    color: $color-dark-primary
}

.modal__title-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
}

.modal__title {
    margin-top: 10px;
    margin-bottom: 15px;

    &:first-child {
        margin-top: 0;
    }
}

.modal__footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    button {
        min-width: 100px;
        padding: 4px 25px;
        margin-bottom: 0;
    }

    flex-flow: row nowrap !important;
}

.modal-dialog--primary {
    .modal__title-icon {
        color: $color-primary;
    }
}

.modal-dialog--success {
    .modal__title-icon {
        color: $color-success;
    }
}

.modal-dialog--warning {
    .modal__title-icon {
        color: $color-yellow;
    }
}

.modal-dialog--danger {
    .modal__title-icon {
        color: $color-fail;
    }
}

.modal-dialog--colored {
    color: $color-white;

    .modal__title-icon {
        color: $color-white;
    }

    .modal__close-btn {
        color: $color-white;
    }

    .modal__footer {
        button:first-child {
            color: $color-white;
            background-color: rgba(255, 255, 255, 0.3);
            border-color: $color-white;

            &:before {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }

        button:last-child {
            border-color: white;
            color: $color-white;
        }
    }

    &.modal-dialog--primary .modal-content {
        background-color: $color-dark-primary;
    }

    &.modal-dialog--success .modal-content {
        background-color: $color-accent;
    }

    &.modal-dialog--warning .modal-content {
        background-color: $color-yellow;
    }

    &.modal-dialog--danger .modal-content {
        background-color: $color-red;
    }
}

.modal-dialog--header {
    max-width: 520px;

    .modal-content {
        padding: 0;
        text-align: left;
    }

    .modal__header {
        color: $color-white;
        padding: 15px 20px;
        position: relative;
    }

    .modal__title {
        margin: 0;
        font-weight: 300;
    }

    .modal__close-btn {
        color: $color-white;
        top: calc(50% - 8px);
    }

    .modal__body {
        padding-top: 25px;
        padding-bottom: 20px;
        padding-right: 40px;
        padding-left: 20px;
    }

    .modal__footer {
        margin-bottom: 40px;
        justify-content: flex-end;
        padding-right: 20px;
    }

    &.modal-dialog--primary .modal__header {
        background-color: $color-dark-primary;
    }

    &.modal-dialog--success .modal__header {
        background-color: $color-success;
    }

    &.modal-dialog--warning .modal__header {
        background-color: $color-yellow;
    }

    &.modal-dialog--danger .modal__header {
        background-color: $color-red;
    }
}

.modal-dialog--carousel {
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 100%;
    user-select: none;
    -moz-user-select: none;

    .modal-content {
        display: flex;
        align-items: center;
        box-shadow: none;
        background: none;
        text-align: left;
    }

    .modal__header {
        color: $color-white;
        height: 50px;
        text-align: right;
        padding: 10px 20px 10px 0;
        position: relative;
    }

    .modal__title {
        margin: 0;
    }

    .modal__close-btn {
        color: $color-white;
    }

    .modal__btn {
        padding: 0;
        cursor: pointer;
        color: transparentize($color-white, 0.5);

        &:hover:not([enabled]) {
            color: $color-white;
        }

        .modal__btn_left {
            width: 80px;
            height: 80px;
            position: absolute;
            left: 0;
        }

        .modal__btn_right {
            width: 80px;
            height: 80px;
            position: absolute;
            right: 0;
        }
    }

    .modal__body {
        max-width: 50%;
        overflow: hidden;

        li {
            align-items: stretch;
        }

        padding-right: 40px;
        padding-left: 20px;
    }

    .modal__footer {
        justify-content: flex-end;
        color: $color-white;
    }
}

@media screen and (max-width: 768px) {
    .modal-dialog--carousel {
        .modal__body {
            max-width: 100%;
        }

        .modal__btn {
            display: none;
        }
    }
}
