.header {
  padding: 0 3.125rem;
  background-color: $color-accent;

  .header__nav-image {
    width: 7.25rem;
    margin-right: 1.875rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .navbar-nav .nav-link,
  .navbar-text {
    color: $color-dark-primary;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
  }
  .navbar-nav .nav-link {
    margin-right: 0.625rem;
  }
}

.landing-page {
  font-size: 16px;

  strong {
    color: $color-red;
  }

  .text-highlight {
    color: $color-primary;
  }

  .landing__hero {
    padding: 7.5rem 0;
    background-color: $color-accent;
    position: relative;

    .row {
      align-items: center;
    }

    .btn-toolbar {
      margin-top: 1.875rem;
      margin-bottom: 0.625rem;

      .btn {
        height: 3.375rem;
        width: 11.8125rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;

        &.btn-primary {
          background-color: $color-dark-primary;
        }

        &.btn-outline-primary {
          color: $color-dark-primary;
          border-color: $color-dark-primary;

          &::before {
            background-color: $color-dark-primary;
          }
        }

        svg {
          height: 1rem;
          width: auto;
          margin-left: 0.625rem;
        }
        &:last-child {
          svg {
            fill: $color-dark-primary;
          }

          &:hover,
          &:focus,
          &:active,
          &:active:focus {
            background-color: $color-dark-primary;
            color: white;
            border: none;

            svg {
              fill: $color-white;
            }

            &::before {
              background-color: $color-dark-primary;
            }
          }
        }
      }
    }

    h1 {
      font-size: 2.6875;
      font-weight: 600;
      line-height: 1.4;
      color: $color-dark-primary;
    }

    p {
      font-size: 1.1875rem;
      width: 90%;
      line-height: 2;
      letter-spacing: -0.57px;
      color: $color-dark-text;
    }

    svg {
      width: 100%;
    }
  }

  .landing__work-smarter {
    padding: 4rem 0 3.125rem;
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 3.5rem;
      text-align: center;
      font-size: 2.125;
      font-weight: 600;
      line-height: 1.47;
      letter-spacing: -0.09px;
      color: $color-dark-primary;
    }

    p {
      position: relative;
      margin-bottom: 2.125rem;
      font-size: 1.25rem;
      padding-top: -0.625rem;
      padding-left: 2.5rem;
      text-align: left;

      &::before {
        content: url(../../shared/img/landing/check.svg);
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .work-smarter__link {
      display: flex;
      justify-content: flex-end;
      a {
        font-size: 1.125rem;
        text-align: center;
        line-height: 1.56;
        color: $color-primary;
      }
    }
  }

  .landing__access {
    padding: 2rem 7.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &.dark {
      background: linear-gradient(to right, #1e4e74, #0561aa 42%, #045fa7);

      p,
      h3 {
        color: $color-white;
      }

      &::before {
        content: url(../../shared/img/landing/riskoncise-sign.svg);
      }

      button {
        color: $color-primary;
      }
    }

    &.light {
      background-color: $color-accent;

      p,
      h3 {
        color: $color-dark-primary;
      }

      &::before {
        content: url(../../shared/img/landing/riskoncise-sign2.svg);
      }

      button {
        background-color: $color-dark-primary;
        color: $color-white;
      }
    }

    &::before {
      position: absolute;
      left: 0;
      height: 9.5rem;
      top: 50%;
      transform: translateY(-50%);
    }

    p {
      font-size: 0.9375rem;
      font-weight: 600;
      line-height: 3.33;
      letter-spacing: -0.09px;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    h3 {
      margin-bottom: 0.875rem;
      font-size: 2.125rem;
      font-weight: 600;
      line-height: 1.47;
      letter-spacing: -0.22px;
      z-index: 1;
    }

    button {
      width: 11.8125rem;
      height: 3.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: none;
      text-transform: uppercase;
      outline: none;
      svg {
        margin-left: 0.625rem;
      }
    }
  }

  .key-features {
    padding: 6.875rem 0;
    background-color: $color-accent;

    .key-features__title {
      text-align: center;
      line-height: 3.125rem;

      h3,
      p {
        color: $color-dark-primary;
      }

      h3 {
        font-size: 2.25rem;
      }

      p {
        font-size: 1.375rem;
      }
    }

    .key-features__content {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;

      .key-features__left {
        display: flex;
        flex-wrap: wrap;
      }

      .key-features__item {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin: 3.125rem 1.625rem 0;
        width: 9.375rem;
      }

      .key-features__item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.3125rem;
        height: 5.3125rem;
        border-radius: 100%;
        box-shadow: 0 3px 5px 0 rgba(30, 78, 116, 0.2);
        background-color: $color-white;
        cursor: pointer;

        &:hover {
          border: 2px solid $color-dark-primary;
        }

        svg {
          zoom: 85%;
        }
      }

      .key-features__item-title {
        margin-top: 0.75rem;
        font-size: 0.9375rem;
        letter-spacing: -0.21px;
        font-weight: 600;
        color: $color-dark-text;
      }

      .key-features__card {
        width: 22.875rem;
        //   height: 303px;
        padding: 1.75rem 1.75rem 1.125rem;
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        color: $color-dark-primary;

        h5 {
          font-size: 1.25rem;
          font-weight: 600;
          letter-spacing: -0.09px;
          color: $color-dark-primary;
          line-height: 25px;
        }

        p {
          font-size: 0.9375rem;
          line-height: 2;
          color: $color-dark-primary;
        }

        a {
          font-weight: 600;
          color: $color-dark-primary;
        }
      }
    }
  }
}

.dashboard-page {
  padding: 3rem 0;

  h3 {
    text-align: center;
    font-size: 2.125rem;
    font-weight: 600;
    line-height: 1.47;
    letter-spacing: -0.09px;
    color: $color-dark-primary;
    margin-bottom: 3.125rem;
  }

  .dashboard-page__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dashboard-page__content-left {
    box-shadow: 0 3px 8px 0 rgba(30, 78, 116, 0.2);
    width: 43%;
    img {
      width: 100%;
      height: auto;
    }
  }

  .dashboard-page__content-right {
    width: 47%;
    display: flex;
    flex-direction: column;
    ul li {
      font-size: 1.125rem;
      line-height: 1.56;
      color: $color-text;
      list-style: none;
      margin-bottom: 0.5rem;
      position: relative;

      &::before {
        content: "";
        display: block;
        background-color: $color-dark-primary;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin-right: 8px;
        position: absolute;
        left: -24px;
        top: 10px;
      }
    }
    a {
      padding-left: 0.9375rem;
      color: $color-primary;
      align-self: flex-end;
    }
  }
}

.footer {
  background-color: $color-dark-primary;
  color: $color-white;
  text-align: center;
  padding: 1.5625rem 0;
  font-size: 0.9375rem;
  letter-spacing: -0.1px;
}

.decoration__blue-box {
  height: 18px;
  width: 100px;
  background-color: $color-dark-primary;
}

.about-us {
  .about-us__content {
    display: flex;
    height: calc(100vh - 164px);
    width: 100%;
    align-items: center;
    justify-content: space-around;
    .about-us__left {
      width: 40vw;
      display: flex;
      justify-content: flex-end;

      img {
        max-width: 500px;
        height: auto;
      }
    }
    .about-us__right {
      width: 40vw;

      h1 {
        text-transform: capitalize;
        font-size: 64px;
        line-height: 64px;
        margin: 20px 0 50px;
        color: $color-light-text;
      }

      p {
        font-size: 18px;
        margin-bottom: 30px;
        width: 80%;

        strong {
          color: $color-red;
        }
      }

      ul {
        list-style: initial;
        li {
          font-size: 18px;
          color: $color-text;
        }
      }
    }
  }
}

.contacts {
  .contacts__content {
    display: flex;
    display: flex;
    height: calc(100vh - 164px);
    width: 90%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-evenly;

    .contacts__left {
      // width: 40vw;
      display: flex;
      justify-content: flex-end;

      img {
        max-width: 400px;
        height: auto;
      }
    }
    .contacts__right {
      // width: 40vw;

      .contacts__right-body {
        display: flex;

        img {
          max-width: 250px;
          height: 100%;
        }
      }

      h1 {
        width: 500px;
        font-size: 48px;
        line-height: 48px;
        margin: 20px 0 50px;
        color: $color-light-text;
      }

      p {
        font-size: 18px;
        margin-bottom: 30px;
        width: 80%;

        strong {
          color: $color-red;
        }
      }

      ul {
        list-style: none;
        li {
          color: $color-text;
          font-size: 18px;
          a {
            text-decoration: underline !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .landing-page {
    .landing__hero {
      text-align: center;

      .row {
        flex-direction: column-reverse;
      }

      p {
        width: 100%;
      }
      .btn-toolbar {
        justify-content: center;
      }
    }

    .landing__access {
      text-align: center;
    }

    .key-features {
      .key-features__left {
        justify-content: center;
      }
      .key-features__content .key-features__card {
        margin: 5rem auto 0;
        width: 80%;
        // height: 200px;
      }
    }

    .dashboard-page .dashboard-page__content {
      flex-direction: column;

      .dashboard-page__content-right {
        width: 100%;
        text-align: center;
      }

      .dashboard-page__content-left {
        width: 60%;
        margin-bottom: 3.125rem;
      }
    }
    .landing__access.dark,
    .landing__access.light {
      &::before {
        zoom: 60%;
      }
    }
    .landing__hero {
      padding: 3rem 0;
    }

    .landing__work-smarter {
      padding: 4rem 0 3.125rem;
    }

    .landing__access {
      padding: 0.625rem 6rem 2rem;
    }

    .key-features {
      padding: 3.875rem 0;
    }

    .dashboard-page {
      padding: 3rem 0;
    }
  }
}

.contacts {
  .contacts__content {
    .contacts__right {
      h1 {
        // width: 100%;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  // .landing__hero {
  //     padding-left: 64px;
  //     padding-right: 64px;
  // }

  html {
    font-size: 14px;
  }

  .landing__access.dark,
  .landing__access.light {
    &::before {
      zoom: 40%;
    }
  }
}

@media screen and (max-width: 575px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 485px) {
  html {
    font-size: 14px;
  }

  .landing-page {
    .landing__hero {
      padding: 2rem 0;
    }

    .landing__work-smarter {
      padding: 4rem 0 3.125rem;
    }

    .landing__access {
      padding: 0.625rem 4rem 2rem;
    }

    .key-features {
      padding: 3.875rem 0;
    }

    .dashboard-page {
      padding: 2rem 0;
    }
  }
  .btn-toolbar {
    flex-direction: column;
    align-items: center;

    & > * {
      margin: 0 0 10px;
      white-space: nowrap;
    }
  }
}
