.risk-form__container {
  padding: 40px 30px 45px 30px;
}

.risk__monte-carlo {
  background-color: $color-accent;
  position: relative;
  left: -30px;
  width: calc(100% + 60px);
  padding: 20px 30px 0 30px;
  margin-bottom: 15px;
}

.mitigations__score {
  height: 68px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
  .mitigations__score-box {
    width: 130px;
    height: 36px;
    background-color: $color-accent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  p {
    color: $color-dark-text;
    font-size: 14px;
    font-weight: 600;
  }
}

// .monte-carlo__container {
  // background-color: $color-accent;
  // position: relative;
  // left: -20px;
  // max-width: initial;
  // padding: 20px 30px 0 30px;
  // width: calc(100% + 38px) !important;
  // margin-bottom: 20px;
  // width: 100em;
// }

.risk-registers__owner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  p {
    font-size: 14px;
  }
  span {
    font-size: 13px;
    letter-spacing: -0.3px;
    color: $color-light-text;
  }
}

.mitigations__subtitle {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: $color-dark-text;
  margin-bottom: 10px;
}

.risk__document-container {
  display: none;
  position: relative;
  background-color: $color-accent;
  width: calc(100% + 60px);
  margin-left: -30px;
  padding: 30px;

  .risk__document-close {
    position: absolute;
    top: 10px;
    right:10px;
    cursor: pointer;
  }

  .btn-toolbar {
    margin-top: 0;
  }

  &.open {
    display: block;
  }
}
