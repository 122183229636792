.panel {
  padding: 19px;
  padding-bottom: 21px;
  border-radius: 5px;
  border: 1px solid #f7f9fa;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  position: relative;

  &.dashboard {
    &:hover {
      border: 1px solid $color-primary;
      background-color: #edf7ff;
      cursor: pointer;
    }

    &.active {
      border: 1px solid $color-primary;
      background-color: #edf7ff;
    }
  }

  .black-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6;
    top: 0;
    left: 0;
    z-index: 2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    border-radius: 5px;
  }

  &.active {
    border: 2px solid #0286c3;
  }

  .panel__header {
    display: flex;
    justify-content: space-between;

    &.full {
      max-width: 30%;
    }

    .panel__title {
      font-size: 15px;
      letter-spacing: 0.1px;
      font-weight: 700;
      text-transform: uppercase;
      color: #4c4c4c;

      &.upper {
        text-transform: uppercase;
      }
    }

    .panel__switches {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      .panel__switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }

      .panel__switch-label {
        font-size: 14px;
        letter-spacing: 0.09px;
        color: #565656;
        margin: 0;
        margin-right: 10px;
      }

      .panel__switch-button {
        display: inline-block;
        width: 50px;
        height: 20px;
        border: 1px solid black;
      }
    }
  }

  .panel__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }

  .panel__column {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;

    &:first-of-type {
      padding-right: 10px;
    }

    &:last-of-type {
      border-left: 1px solid #d5dbde;
      padding-left: 10px;
    }
  }

  .panel__content {
    padding-top: 0;
    padding-bottom: 40px;
  }
}

.risk-table--open {
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}

table {
  font-size: 13px;
  white-space: nowrap;

  tbody {
    tr {
      border-color: white;
    }
  }
}

.risk-table {
  thead {
    th {
      padding: 4px 5px !important;
    }
  }
  td {
    padding: 4px 5px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    // text-align: center;
    max-width: 100px;

    &:nth-child(2) {
      // padding: 4px 10px;
      // text-align: start;
    }

    &:last-child {
      padding: 4px 0 !important;
    }
  }
}

.table {
  margin-bottom: 0;

  & > :not(caption) > * > * {
    padding: 4px 0;
  }
}

.triangle-right {
  width: 0;
  height: 0;
  display: inline-block;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 8px solid #0286c3;
}

.triangle-up {
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid #c9452b;
}

.triangle-down {
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #009688;
}

.dashboard__cards {
  display: flex;
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    padding: 0 10px;
    height: 10px;
    opacity: 0.3;
    background-color: $color-light-gray;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 10px;
    border: 2.5px solid $color-light-gray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
  }

  .card {
    min-width: 400px;
    margin-right: 30px;
    margin-bottom: 20px;

    &:last-of-type {
      // margin-right: 0;
    }
  }

  .col:last-child {
    .card {
      margin-right: 0;
    }
  }
}

.risk-breakdown__controls {
  display: flex;
  align-items: center;

  .risk-breakdown__select {
    margin-right: 20px;

    span {
      margin-left: 10px;
    }
  }
}

.risk-breakdown__board {
  margin-left: auto;
  width: 320px;
  height: 100px;
  text-align: right;
  background-color: $color-accent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  p {
    &:first-child {
      font-size: 15px;
      font-weight: 600;
    }
    &:last-child {
      font-size: 24px;
      color: $color-red;
      font-weight: 700;
      letter-spacing: 0.09px;
    }
  }
}

.risk-breakdown__bars {
  width: 100%;

  & > * {
    margin-top: 25px;

    &:last-child {
      margin-bottom: 25px;
    }
  }
}

@media screen and (min-width: 1530px) {
  .dashboard__cards {
    &::-webkit-scrollbar {
      height: 0;
    }
  }
}
