.progress__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .progress {
    border-radius: 5px;
    background-color: #d5dbde;
  }

  .progress-bar {
    border-radius: 5px;
  }

  .progress__wrap-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .progress__info-left {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .progress__left-amount {
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    width: 130px;
    letter-spacing: 0.1px;
  }

  .progress__left-label {
    font-size: 14px;
    color: #777;
  }

  .progress__info-right {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1px;
  }

  &.red {
    .progress__left-amount,
    .progress__info-right {
      color: #c01f28;
    }
    .progress-bar {
      background-color: #c01f28;
    }
  }

  &.dred {
    .progress__left-amount,
    .progress__info-right {
      color: #84151b;
    }
    .progress-bar {
      background-color: #84151b;
    }
  }

  &.dblue {
    .progress__left-amount,
    .progress__info-right {
      color: #095679;
    }
    .progress-bar {
      background-color: #095679;
    }
  }

  &.blue {
    .progress__left-amount,
    .progress__info-right {
      color: #009cd6;
    }

    .progress-bar {
      background-color: #009cd6;
    }
  }
}

.risk_tooltip {
  // background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #edebf3;
  // width: 350px;
  // top: 100px;
  .tooltip-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  h5 {
    width: 173px;
    text-align: left;
    color: #222;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #dae0e0;
    padding-bottom: 6px;
    margin-bottom: 6px;
  }
}

.risk_tooltip-inner {
  background-color: rgba(255, 255, 255, 1);
  opacity: 1;
  max-width: fit-content;

  table {
    th,
    td {
      border-bottom: none;
      text-align: left;
      padding: 2px 5px;
      font-size: 14px;
    }
  }
}
