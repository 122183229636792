@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slick-slider {
  width: calc(100% + 15px);
  // margin-bottom: 24px;

  .slick-slide {
    overflow: hidden;
    padding-right: 15px;
    position: relative;
  }

  .slick-list {
    width: 100%;
    margin-bottom: 10px;
  }

  .slick-arrow {
    height: 24px;
    width: 24px;
    z-index: 1;

    &:before {
      color: $color-dark-primary;
      font-weight: 500;
      position: absolute;
      height: 24px;
      font-size: 30px;
      line-height: 20px;
      font-family: inherit;
      width: 10px;
      transition: all 0.3s;
    }

    &:hover {
      color: $color-additional;
    }

    @media screen and (max-width: 750px) {
      display: none !important;
    }
  }

  right: 0;

  .slick-arrow.slick-next {
    left: auto;
    direction: ltr;
    background: #d5dbde;
    border-radius: 50%;
    right: 25px;
    top: -10px;
    // width: 24px;
    // height: 24px;

    &:before {
      content: "\203A";
      right: 5px;
      top: 0;
    }
  }

  // left: -15px;

  .slick-arrow.slick-prev {
    // background: linear-gradient(-90deg, transparent, white);
    direction: ltr;
    left: auto;
    direction: ltr;
    background: #d5dbde;
    border-radius: 50%;
    right: 60px;
    top: -10px;

    &:before {
      content: "\2039";
      right: 7px;
      top: 0;
    }
  }

  .slick-dots {
    width: calc(100% - 15px);
    li {
      width: 10px;

      button {
        padding: 0;
        width: 10px;

        &:before {
          font-size: 10px;
          width: 10px;
          height: 10px;
          color: $color-dusty-white;
          opacity: 1;
          transition: all 0.3s;
        }
      }

      &.slick-active {
        button:before {
          color: $color-dark-primary;
        }
      }

      &:hover {
        button:before {
          color: $color-additional;
        }
      }
    }
  }

  &.slick-slider--single {
    .slick-arrow {
      background: transparent;
    }
  }

  .slick-slider__caption {
    position: absolute;
    bottom: 20px;
    left: 30px;
    width: calc(100% - 50px);
  }

  .slick-slider__caption-title {
    font-size: 16px;
    font-weight: 500;
    color: white;
    line-height: 20px;
  }

  .slick-slider__caption-description {
    font-size: 12px;
    color: $color-additional;
    margin: 0;
    line-height: 16px;
  }
}
