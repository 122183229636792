.accept-invite {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url(https://riskonciseqast.blob.core.windows.net/b2ctemplates/bg.png);
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .accept-invite__container {
    padding: 40px 50px;
    max-width: 530px;
    height: 100%;
    background-color: rgba($color: #fff, $alpha: 0.7);
    box-shadow: 0 5px 10px 0 rgba($color: #000000, $alpha: 0.2);

    .btn {
      width: 100%;
    }

    p {
      text-align: center;
      font-size: 18px;
      color: $color-dark-primary;
      margin-bottom: 15px;
    }

    .form__form-group-field {
      position: relative;
      &:before {
        content: url(https://riskonciseqast.blob.core.windows.net/b2ctemplates/lock_icon.svg);
        position: absolute;
        left: 10px;
        top: 20px;
        height: 24px;
        transform: translateY(-50%);
      }

      input {
          padding-left: 40px;
          background-color: rgba($color: #fff, $alpha: 0.7);
      }
    }
  }
}
