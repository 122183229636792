.sidebar {
  position: fixed;
  bottom: 0;
  z-index: 99;
  height: 100vh;
  width: 240px;
  box-shadow: 1px 0px 0px 0px $color-dusty-white;
  padding-top: 60px;
  transition: transform 0.3s;
  overflow-x: auto;
  background: $color-white;

  &::-webkit-scrollbar {
    width: 0;
  }

  a {
    display: block;
  }

  &.sidebar--show {
    display: block;
    transform: translateX(0);

    .sidebar__back {
      display: block;
    }
  }
}

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
}

.sidebar__link-active {
  .sidebar__link {
    &:before {
      opacity: 1;
    }
    .sidebar__link-icon {
      color: $color-dark-primary;
    }
    color: $color-dark-primary;
    font-size: 16px;
  }
  background: $color-accent;
  border-radius: 5px;
}

.sidebar__scroll {
  width: 240px;

  & > div {
    height: calc(100vh - 60px);
  }

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.sidebar__content {
  padding: 15px 0 0;
  height: 100%;
  overflow: auto;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
}

.sidebar__block {
  padding: 15px 0;
  border-bottom: 1px solid $color-dusty-white;
  margin: 0 4px;

  &:last-child {
    border: none;
  }
}

.sidebar__link {
  height: 48px;
  width: 240px;
  border-radius: 5px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: $color-dark-text;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    // width: 100%;
    background: $color-accent;
    opacity: 0;
    transition: all 0.3s;
    left: 0px;
  }

  p {
    position: absolute;
    width: 180px;
    transition: left 0.3s;
    top: 50%;
    transform: translateY(-50%);
    text-align: start;
    left: 53px;
  }

  &:hover {
    text-decoration: none;
    width: 100%;
    background-color: $color-accent;

    &:before {
      opacity: 1;
    }
  }
}

.sidebar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.sidebar__link-title.dot {
  &::before {
    content: url(../../shared/img/circle.svg);
    position: absolute;
    left: -20px;
  }
}

.sidebar__link-icon {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  color: $color-dark-text;
  margin-right: 10px;
}

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  width: calc(100% - 1px);
  padding: 15px 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

  .sidebar__link-active {
    background-color: $color-white;
  }

  .sidebar__link {
    height: 32px;
  }

  & .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    .sidebar__link {
      padding-left: 53px;

      p {
        left: 53px;
      }
    }
  }

  .sidebar__link {
    padding-left: 43px;

    &:hover {
      border-color: $color-white;
      background-color: $color-accent;
      color: $color-dark-primary;
    }
  }

  .sidebar__link {
    display: block;
  }
}

.rtl-support {
  .sidebar__category-icon {
    transform: rotate(180deg);
  }
}

.sidebar__category-icon {
  position: absolute;
  font-size: 14px;
  line-height: 30px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: $color-dark-text;
  right: 15px;
}

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;
  margin-left: 5px;

  span {
    position: absolute;
    top: 3px;
    width: 26px;
    text-align: center;
    left: 0px;
  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-wrap {
  &.sidebar__category-wrap--open {
    .sidebar__category-icon {
      transform: rotate(90deg);
    }

    .sidebar__link-active {
      background: transparent;
    }

    background: $color-accent;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &.admin {
    background-color: $color-dark-primary;

    .sidebar__link-icon,
    .sidebar__link-title,
    .sidebar__category-icon {
      color: $color-white;
    }
  }
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  background: $color-red;
  margin-left: 5px;
}

@media screen and (min-width: 576px) {
  .sidebar-collapse-wrapper {
    &:hover {
      .sidebar__category {
        width: 235px !important;
      }
      .sidebar__submenu-wrap {
        display: inherit !important;
      }
    }
  }

  .sidebar {
    &.sidebar--no-desktop {
      &.sidebar--show {
        transform: translateX(0);
      }

      .sidebar__wrapper--mobile {
        display: block;
      }
    }

    &.sidebar--collapse {
      width: 60px;
      overflow: visible;
      transition: transform 0.3s, width 0.3s;

      .sidebar__scroll,
      .sidebar__content {
        width: 55px;
        overflow: visible !important;
        transition: width 0.3s;
      }

      .sidebar__submenu {
        padding: 0 0 15px 0;
        transition: 0s;
      }

      .sidebar__category-wrap {
        &:hover {
          .sidebar__category {
            width: 240px;
            // background: $color-accent;

            &:before {
              opacity: 1;
            }
          }
        }
      }

      .sidebar__submenu-wrap {
        position: absolute;
        width: 0;
        transition: 0.3s;
        display: none;
        left: 55px;

        .sidebar__link {
          width: 185px;
          padding-left: 15px;

          p {
            position: relative;
            animation: none;
            left: 0px;
          }
        }

        .sidebar__submenu-wrap {
          position: relative;
          left: 0px;

          .sidebar__link {
            padding-left: 30px;
          }
        }
      }

      .sidebar__link-active {
        .sidebar__link {
          color: $color-dark-primary;
        }
      }

      .sidebar__link {
        overflow: hidden;
        width: 55px;
        background: $color-white;
        border-radius: 0px;

        p {
          position: absolute;
          width: 160px;
          left: 70px;
        }

        &:hover {
          background: $color-accent;
        }
      }

      .sidebar__category-icon {
        opacity: 0;
        transition: opacity 0s;
      }

      .scrollbar-track.scrollbar-track-y {
        margin-right: 188px;
      }
    }
  }

  .sidebar__wrapper--desktop {
    display: block;
  }

  .sidebar__wrapper--mobile {
    display: none;
  }
}

@media (max-width: 575px) {
  .sidebar {
    display: none;
    position: fixed;
    top: 60px;
    padding-top: 0;
    z-index: 101;
  }

  .sidebar__content {
    padding-top: 0;
  }
}

@media screen and (min-width: 1300px) {
  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .sidebar.sidebar--no-desktop {
    display: none;
  }

  .sidebar.sidebar--no-desktop.sidebar--show {
    display: block;
  }
}
