.table__container {
  padding: 20px 10px;

  .table__header {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-size: 15px;
    }
  }

  .table__header--right {
    display: flex;
    align-items: center;

    .table__header-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // max-width: 75px;
      margin-left: 20px;
      font-size: 16px;
      cursor: pointer;

      p {
        margin: 0 0 0 5px;
        font-weight: 600;
        font-size: 15px;
        color: $color-dark-primary;
      }
      .table__header-button--icon {
        color: $color-dark-primary;
      }
      &:last-of-type {
        margin-right: 20px;
      }
    }
  }

  .table {
    overflow-x: scroll;
    border-radius: 5px;
    border: solid 1px #f7f9fa;
    border-top: none;
    min-height: 400px;
    max-height: 500px;

    &::-webkit-scrollbar {
      height: 0px;
      width: 10px;
      opacity: 0.3;
      background-color: $color-light-gray;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      width: 10px;
      border: 2.5px solid $color-light-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
    }

    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    table {
      width: inherit;
    }

    thead tr {
      position: sticky;
      top: 0;
      border-bottom: none;
      z-index: 1;
      border-top-left-radius: 5px;
    }

    thead th {
      min-height: 45px !important;
      min-width: 100px;
      padding: 12px 20px;
      position: relative;
      font-size: 13px;
      top: 0;
      position: sticky;

      &:first-child {
        min-width: 45px;
      }
      color: $color-primary;
      border-bottom: none;
      background: $color-accent;

      &:first-child {
        border-top-left-radius: 5px;
      }
    }

    tbody td,
    th {
      border: none;
      color: $color-text;
    }

    tbody tr {
      border-bottom: 1px solid $color-dusty-white;

      &:hover {
        background-color: $color-accent;
      }
    }

    tbody td {
      padding: 12px 20px;
      font-size: 13px;
      font-weight: 600;

      .dropdown {
        button.btn.btn-secondary {
          min-width: 10px;
          background-color: transparent;
          border: none;

          &::before {
            background-color: transparent;
          }
        }
        .dropdown-menu {
          min-width: 112px;
          font-size: 13px;
          letter-spacing: 0;

          .dropdown-item {
            &:hover {
              background: transparent;
            }

            &:last-of-type {
              &:hover {
                color: $color-red;
                display: flex;
                align-items: center;
                &::after {
                  content: "";
                  display: inline-block;
                  background-image: url("../../shared/img/redCross.svg");
                  background-size: 10px 10px;
                  width: 10px;
                  height: 10px;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
    }

    .th-badge {
      font-size: 100%;
      color: $color-primary;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }

    tfoot td {
      min-height: 45px;
      min-width: 100px;
      padding: 10px 0 10px 0;
      font-family: "Roboto", sans-serif;
      color: $color-text;
      font-weight: 700;

      &:first-child {
        min-width: 45px;
        white-space: nowrap;
      }
    }
  }

  .table__search {
    border-bottom: 1px solid $color-dark-primary;
    display: flex;
    justify-content: space-between;
    width: 20em;
    padding-bottom: 5px;
    color: $color-dark-primary;

    input {
      border: none;
      outline: none;
      font-size: 14px;
      color: $color-dark-primary;
      letter-spacing: 0.45px;
      &::placeholder {
        color: $color-dark-primary;
        letter-spacing: 0.45px;
      }
    }

    .table__search-icon {
      color: $color-dark-primary;
    }
  }

  .react-dnd-draggable {
    z-index: 98 !important;

    td {
      width: inherit;

      &:first-child {
        width: 70px;
        min-width: 70px !important;
      }
    }
  }

  .table__footer {
    min-height: 45px;
    background-color: $color-light-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .table__footer-pages {
      display: flex;
      align-items: center;

      button {
        background-color: $color-light-gray;
        border: none;
        outline: none;
        font-size: 14px;
      }

      .pagination__item {
        list-style: none;

        .pagination__link {
          color: $color-text;

          button {
            font-size: 18px;
          }
        }
      }

      .page-item.active .page-link {
        background-color: transparent;
        border: none;
        outline: none;
        color: $color-primary;
      }

      .page-link {
        padding: 0 6px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .table__footer-results {
      select {
        border: none;
        outline: none;
        padding: 1px 2px;
      }

      span {
        margin-right: 20px;
      }
    }
  }
}
