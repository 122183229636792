@import "~react-datepicker/dist/react-datepicker.css";
// @import "~rc-time-picker/assets/index.css";

.form {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  direction: ltr;

  input,
  textarea,
  .form__form-file-field {
    width: 100%;
    padding: 5px 10px;
    font-size: 16px;
    height: 42px;
    transition: border 0.3s;
    background: $color-background;

    &::-webkit-input-placeholder {
      color: $color-light-text;
    }
    &::-moz-placeholder {
      color: $color-light-text;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-light-text;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-light-text;
    }

    border: none;
    border-bottom: 2px solid $color-dusty-white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: $color-dark-text;

    &[disabled] {
      background: $color-disabled;

      &:focus,
      &:active {
        // border-color: #eff1f5;
        border-bottom-color: $color-primary;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $color-primary;
    }

    &.threat {
      &.vlow {
        background-color: rgba($color: #009688, $alpha: 0.3);
      }
      &.low {
        background-color: rgba($color: #ffab00, $alpha: 0.3);
      }
      &.medium {
        background-color: rgba($color: #f05e1b, $alpha: 0.3);
      }
      &.high {
        background-color: rgba($color: #c01f28, $alpha: 0.3);
      }
      &.vhigh {
        background-color: rgba($color: #000000, $alpha: 0.3);
      }
    }

    &.opportunity {
      &.vhigh {
        // background-color: rgba($color: #00004d, $alpha: 0.7);
        background-color: #222b35;
        color: $color-white;
      }
      &.high {
        // background-color: rgba($color: #002366, $alpha: 0.3);
        background-color: #203764;
        color: $color-white;
      }
      &.medium {
        // background-color: rgba($color: #0047ab, $alpha: 0.3);
        background-color: #305496;
        color: $color-white;
      }
      &.low {
        // background-color: rgba($color: #26619c, $alpha: 0.3);
        background-color: #8ea9db;
        color: $color-black;
      }
      &.vlow {
        // background-color: rgba($color: #87ceeb, $alpha: 0.3);
        background-color: #d9e1f2;
        color: $color-black;
      }
    }
  }

  .form__form-group-documents {
    display: flex;
    flex-direction: column;

    a {
      color: $color-primary;
      text-decoration: underline !important;
      max-width: 60%;

      &:hover {
        color: $color-dark-primary;
      }
    }

    .form__form-group-document {
      display: flex;
      align-items: center;

      .remove-document {
        display: flex;
        align-items: center;
        color: $color-red;
        margin-left: 20px;
        cursor: pointer;

        &:hover {
          color: $color-dark-red;
        }
      }
    }
  }

  textarea {
    min-height: 85px;
  }

  &.form--horizontal {
    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;

      &.multiline {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;

      & + .form__form-group-field {
        margin-left: 0px;
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0px;
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }

    @media screen and (min-width: 480px) {
      .form__form-group-label {
        width: 120px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }

      .form__form-group-description,
      .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0px;
  }
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;

  .form__switch-group {
    .form__switch-group-label {
      font-size: 14px;
      margin-right: 10px;
    }
  }
}

.form__form-group--address {
  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
  direction: ltr;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;
  color: $color-text;
  font-size: 14px;
  position: relative;

  span {
    color: $color-fail;
  }
}

.form__form-group-info {
  position: absolute;
  bottom: 5px;
  right: -20px;
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;
  background: $color-light-text;
  border: 1px solid $color-light-text;
  // background: #f2f4f7;
  // border: 1px solid #f2f4f7;

  svg {
    fill: $color-gray;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: $color-white;
    }
  }
}

.form__form-group-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    width: 100%;
    white-space: nowrap;
  }
  //   border-radius: 5px;
  //   line-height: 18px;
  //   font-size: 12px;
  //   padding: 4px 20px;
  //   position: relative;
  //   cursor: pointer;
  //   transition: all 0.3s;
  //   text-align: center;
  //   border: 1px solid $color-primary;
  //   color: $color-text;
  //   background-color: transparent;

  //   &:before {
  //     position: absolute;
  //     height: 0;
  //     width: 0;
  //     border-radius: 50%;
  //     background-color: $color-primary;
  //     transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  //     z-index: 0;
  //     content: "";
  //     top: 0;
  //     transform: translate(-50%, -50%);
  //     left: 0px;
  //   }

  //   &:hover {
  //     outline: none;
  //     box-shadow: none !important;

  //     &:before {
  //       height: 500%;
  //       width: 225%;
  //     }
  //   }

  //   &:focus,
  //   &:active,
  //   &:active:focus {
  //     &:before {
  //       transition: all 0s;
  //     }
  //   }
  // }

  .form__form-file-field {
    // margin: auto 30px auto auto;
    width: 70%;
    display: flex;
    align-items: center;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;
  background: #f2f4f7;
  border: 1px solid #f2f4f7;

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 10px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
  direction: ltr;
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: $color-dark-red;
  margin-bottom: -5px;
  display: block;
  margin-top: 10px;
}

.form__form-group-input-wrap--error-above {
  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0px;
    top: 0;
    padding: 5px 10px;
    background: $color-red;
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid $color-red;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0px;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0px;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0px;
    }
  }
}
.form.form--color-picker .form__form-group {
  @media screen and (max-width: 767px) {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.form--color-picker {
  @media screen and (max-width: 767px) {
    justify-content: space-between;
  }
}

.form-title {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.form__navigation {
  display: flex;
  justify-content: center;
  margin: 20px 0 20px 0px;

  .form__navigation-item {
    display: flex;
    flex-direction: column;
    width: 100px;
    margin: 0 30px;
    cursor: pointer;

    .page-number {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      margin: 0 auto;
      border: 1px solid $color-gray;
      border-radius: 50%;
    }

    .page-title {
      line-height: 20px;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 0;
    }

    &:not(:last-child) {
      .page-number {
        background: $color-white;
        &::before {
          position: absolute;
          content: "";
          right: -126px;
          top: 0%;
          width: 126px;
          height: 50%;
          border-bottom: 2px solid black;
        }
      }
    }

    &.active {
      .page-number {
        background-color: $color-primary;
        border: none;
        color: $color-white;
      }
      .page-title {
        color: $color-primary;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .form__navigation {
    .form__navigation-item {
      &:not(:last-child) {
        .page-number {
          &::before {
            right: -116px;
            width: 116px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .form__navigation {
    .form__navigation-item {
      margin: 0 10px;

      .page-title {
        font-size: 13px;
      }

      &:not(:last-child) {
        .page-number {
          &::before {
            right: -63px;
            width: 63px;
          }
        }
      }
    }
  }
}
