* {
  a {
    text-decoration: none !important;
  }
}

main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif !important;
  font-size: 14px;
  line-height: 1.6;
  position: relative;
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
  list-style-image: url("../../shared/img/circle.svg");
}

a {
  color: $color-dark-primary;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-dark-primary;
  }
}

img {
  width: 100%;
}
