.select-options {
  margin: 0 4px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;
  color: $color-text;

  &:focus {
    outline: none;
  }

  option {
    background-color: $color-background ;
  }
}