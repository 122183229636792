// Colors
$label-colour: #d5dbde;
$disabled-colour: #ddd;
$color-off: #333;

.toggle-switch {
  position: relative;
  width: 49px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $label-colour;
    border-radius: 5px;
    margin: 0;
    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 2px $color-dark-primary;
      }
    }
    > span:focus {
      outline: none;
    }
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 20px;
      padding: 0;
      line-height: 20px;
      font-size: 8px;
      color: $color-white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 8px;
      background-color: $color-primary;
      color: $color-white;
    }
  }
  &-disabled {
    background-color: $disabled-colour;
    cursor: not-allowed;
    &:before {
      background-color: $disabled-colour;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 8px;
    background-color: $label-colour;
    color: $color-off;
    text-align: right;
  }
  &-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    margin: 1px;
    background: $color-white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 28px;
    border: 0 solid $label-colour;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: all 0.3s ease-in 0s;
    color: $color-off;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
}

.switch__wrap {
  display: flex;
  align-items: center;

  p {
    margin-right: 10px;
  }
}
