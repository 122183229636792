$color-accent: #e1f3fb;

$color-primary: #0074d0;
$color-dark-primary: #074663;

$color-red: #c01f28;
$color-dark-red: #84151b;

$color-yellow: #f6da6e;
$color-light-yellow: #f4dd87;
$color-dark-yellow: darken($color-yellow, 10%);

$color-orange: #f05e1b;

$color-fail: #be545a;
$color-success: #009688;

$color-text: #555;
$color-light-text: #777;
$color-dark-text: #222;

$color-black: #000000;
$color-gray: #787985;
$color-light-gray: #eaeaea;
$color-dusty-white: #dddddd;
$color-white: #ffffff;
$color-disabled: #f1f1f1;

$color-background: #f7f9fa;
$color-additional: #999;
