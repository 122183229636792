.gantt-chart__page {
  // padding: 0 10px;
  margin: 20px 0;
  .gantt-chart__card-container {
    display: flex;
    flex: 1 1 100%;
    max-height: 600px;
    overflow: auto;
    align-content: flex-start;
    flex-flow: row wrap;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      height: 0px;
      width: 10px;
      opacity: 0.3;
      background-color: $color-light-gray;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      width: 10px;
      border: 2.5px solid $color-light-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
    }
  }
}

.risk__card-container {
  display: flex;
  flex-direction: column;
  width: 280px;
  overflow: hidden;
  margin: 4px 8px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 20px;

  &.ghost {
    height: 0;
    min-height: 0;
    visibility: hidden;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  &:hover {
    border: 1px solid $color-primary;
    transform: translateY(-2px);
  }

  .risk__card-id,
  .risk__card-owner {
    color: $color-light-text;
  }

  .risk__card-id {
    font-size: 12px;
  }

  .risk__card-title {
    font-size: 18px;
    font-weight: 700;
  }
}

.gantt__controls {
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  .gantt__view-container {
    display: flex;
  }
}

.gantt__table {
  // overflow-x: scroll;
  max-height: 500px;
  // width: 1200px;

  &::-webkit-scrollbar {
    height: 0px;
    width: 10px;
    opacity: 0.3;
    background-color: $color-light-gray;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 10px;
    border: 2.5px solid $color-light-gray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
  }
  table {
    width: inherit;
    thead {
      background-color: $color-accent;
      color: $color-primary;
      font-weight: 700;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .gantt__schedule-month {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
    thead,
    tbody {
      display: block;
      overflow-x: hidden;

      .gantt__schedule-day {
        display: inline-flex;
        width: 70px;
        justify-content: center;
      }
    }
    .gantt__tasks-container {
      padding: 0 10px;
      width: 420px;
      display: flex;
      align-items: center;
      height: 50px;

      .gantt__tasks-title {
        flex: 2;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .gantt__tasks-from,
      .gantt__tasks-to {
        flex: 1;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .gantt__tasks-id {
        flex: 1.2;
        max-width: 90px;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    tbody {
      & > *:nth-child(even) {
        background-color: #eee;
      }
      .gantt__schedule-days {
        height: 50px;
        position: relative;
        .gantt__schedule-day {
          width: 70px;
          display: inline-block;
          height: 50px;
          border-left: 1px solid #dddddd;
          border-right: 1px solid #dddddd;
        }
      }
    }
  }

  .gantt__schedule-block {
    height: 20px;
    position: absolute;
    background-color: $color-primary;
    top: 50%;
    transform: translateY(-50%);

    .gantt__schedule-tooltip-holder {
      position: relative;
    }

    &.risk {
      background-color: $color-red;
    }
  }

  .gantt__task-row {
    position: relative;

    .gantt__task-icon {
      cursor: pointer;
      top: 45%;
      font-size: 18px;
      transform: translateY(-50%);
    }

    &:last-of-type {
      border-bottom: 1px solid #dddddd;
    }
  }
}

.gantt__schedule-tooltip {
  display: none;
  position: fixed;
  max-width: 380px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

  &.open {
    display: block;
    z-index: 20;
  }

  .gantt__tooltip-container {
    padding: 10px;

    th,
    td {
      padding: 2px 5px;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
