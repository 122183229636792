.dropzone {
  width: 100%;
  display: flex;
  cursor: pointer;
  border: 1.5px dashed $color-dark-text;
  border-radius: 7.5px;

  &.dropzone--single {
    height: 212px;
    position: relative;
  }

  .dropzone__input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    span {
      font-size: 18px;
      line-height: 1.5;
      color: $color-text;
      text-align: center;
      max-width: 28em;
    }

    a {
      text-decoration: underline !important;
      color: $color-primary;
    }

    .dropzone__input-icon {
      font-size: 70px;
      margin-bottom: 14px;
    }
  }
  .dropzone__preview {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;

    .dropzone__preview-delete {
        margin-top: 20px;
    }
  }
}

.upload-instructions {
  margin-top: 44px;

  .instructions__faq {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: $color-dark-primary;

    .instructions__faq-icon {
      margin-right: 14px;
    }
  }

  .instructions__steps {
    font-size: 15px;

    ul {
      padding-left: 0;

      li {
        margin-top: 15px;
        list-style: none;
      }
    }

    .instructions__step-title {
      font-weight: 600;
    }
  }
}
