.react-select {
  width: 100%;
  // height: 42px;
  font-size: 16px;
  border: none;

  &.react-select--is-disabled {
    .react-select__control {
      background-color: #f1f1f1 !important;
    }
  }
}

.react-select__control {
  min-height: 42px;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  transition: all 0.3s;
  border: none !important;
  border-bottom: 2px solid $color-dusty-white !important;
  background-color: $color-background !important;

  &.react-select__control--is-focused,
  &:hover {
    box-shadow: none;
    background: transparent;
  }
  &.react-select__control--is-focused {
    border-bottom-color: $color-primary !important;
  }
}

.react-select__input {
  height: 32px;
  color: $color-text;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator,
.react-select__clear-indicator {
  cursor: pointer;
  color: $color-dark-primary;

  svg {
    height: 16px;
    width: 16px;
  }
}

.react-select__multi-value {
  background-color: transparent;
  border: 1px solid $color-dark-primary;

  .react-select__multi-value__label {
    padding: 3px 6px;
    border-right: 1px solid $color-dark-primary;
    color: $color-text;
  }
}

.react-select__multi-value__remove {
  opacity: 0.8;
  transition: 0.3s;
  cursor: pointer;
  color: $color-text;
}

.react-select__multi-value__label,
.react-select__multi-value__remove {
  background: $color-background;
}

.react-select__single-value {
  color: $color-text;
}

.react-select__menu {
  box-shadow: none !important;
  margin-top: 6px;
  margin-bottom: 6px;
}

.react-select__menu-list {
  top: calc(100% + 1px);
  border-radius: 0;
  box-shadow: none;
  font-size: 12px;
  overflow: hidden;
  background: $color-white;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px #edebf3;
}

@keyframes open {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 200px;
  }
}

.react-select__option {
  transition: all 0.3s;
  border-radius: 0;
  display: flex;
  cursor: pointer;
  padding: 8px 10px;
  background: $color-background;
  color: $color-text;

  &.react-select__option--is-focused {
    background: $color-accent;
    color: $color-text;
  }
}

.react-select__color {
  display: block;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0px;
  margin-left: 5px;

  .form__select-color {
    display: flex;
    flex-wrap: wrap;
    max-width: 84px;
    margin-right: 40px;
  }
}
.react-select__value-container {
  padding: 0px 8px !important;

  &.react-select__value-container--is-multi.react-select__value-container--has-value {
    padding: 2px 8px !important;
  }
}
