.date-picker {
  width: 100%;
  position: relative;
  cursor: pointer;

  &:hover {
    .date-picker__icon {
      color: $color-dark-primary;
    }
  }

  .date-picker__icon {
    position: absolute;
    right: 0;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    pointer-events: none;
    cursor: pointer;
  }

  & > div,
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
  }

  &.date-picker--interval {
    display: flex;

    & > div,
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      max-width: 170px;
    }
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: $color-text;
  }

  .react-datepicker__header {
    background-color: $color-dark-primary;
    border-color: transparent;
    border-radius: 0;
  }

  .react-datepicker__current-month {
    color: $color-text;
  }

  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: $color-primary;
    font-weight: 600;
  }

  .react-datepicker__navigation--next {
    border-left-color: $color-text;

    &:hover {
      border-left-color: $color-text;
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: $color-text;

    &:hover {
      border-right-color: $color-text;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: $color-dark-primary;
    transition: all 0.3s;

    &:hover {
      background-color: $color-dark-primary;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    border-bottom-color: $color-dark-primary;
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    border-left: 1px solid $color-dusty-white;
    width: 85px !important;
  }
  .react-datepicker__time-box {
    width: 85px !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: $color-dark-primary !important;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: $color-white !important;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    border: solid 0.5px #edebf3;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $color-white !important;
    color: $color-text;
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: $color-text;
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: $color-primary !important;
    border-radius: 50%;
    color: $color-white;
  }
}
