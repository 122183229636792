.piechart__stat {
  display: flex;
  flex-direction: column;
  width: calc(100% - 25px);
  //   margin-top: 40px;
  //   margin-bottom: 10px;
  //   justify-content: space-between;
  align-items: center;
  position: relative;
  // left: -25px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.sm {
    position: relative;
    top: -20px;
    // left: 12vw;
    align-items: center;
    width: calc(100% + 150px);
  }

  .pirchart__state-info {
    position: absolute;
    top: 0;
    // right: 10px;
  }

  .piechart__title {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    align-self: flex-start;
    margin-bottom: 15px;
    // margin-left: 30px;
  }

  .piechart__stat-info {
    margin-top: auto;
    margin-bottom: auto;
  }

  .piechart__stat-number {
    margin-top: 10px;
  }

  .piechart__stat-chart {
    position: relative;
    text-align: center;
    left: -30px;
  }

  .piechart__stat-label {
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 700;
    margin: 0;
    animation: label 1.5s ease-in;
    color: black;

    &.sm {
      font-size: 13px;
    }

    span {
      display: block;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1340px) {
  .piechart__stat {
    width: calc(100% - 25px);

    &.sm {      
    width: calc(100% + 150px);
  }
  }
}
