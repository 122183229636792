.user-invitation {
  width: 100%;
  padding: 30px 60px;

  h4 {
    font-size: 16px;
    font-weight: 700;

    &:first-of-type {
      margin-bottom: 10px;
    }

    span {
      color: $color-red;
    }
  }

  .user-invitation__permissions {
    width: 100%;
    margin: 20px 0 40px;
    .permissions__option {
      border: 1px solid $color-light-gray;
      border-radius: 5px;
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 20px 0;

      &.active {
        border-color: $color-primary;
      }

      h5 {
        font-weight: 700;
      }

      input {
        min-width: 14px;
        min-height: 14px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }

      .permisstions__options--icon {
        font-size: 20px;
        margin: 0 20px;
        color: $color-primary;
      }
    }
  }
  .btn-toolbar {
    margin: 0 0 30px auto;
  }
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px 30px;
  text-align: left;

  h5 {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 15px;
  }

  ul {
    padding-left: 0;
    li {
      list-style: none;
      margin-bottom: 10px;
      display: flex;

      p,
      span {
        font-size: 15px;
      }

      p {
        min-width: 230px;
        text-align: left;
        font-weight: 600;
      }
    }
  }

  .btn-toolbar {
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
}

.remove-user {
  padding: 30px 30px 60px;
  h5 {
    font-weight: 700;
  }

  .remove-user__content {
    margin-top: 30px;

    .btn-toolbar {
      width: 100%;
      justify-content: center;
    }
  }
}