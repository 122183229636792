.forum {
  .btn-toolbar:last-child {
    margin-bottom: 10px;
  }
}

.forum__content {
  display: flex;
  flex-direction: column;

  & > * {
    margin: 20px 0;
  }
}

.forum-post {
  padding: 20px;

  .post__menu {
    width: 10px;

    .dropdown-menu.show {
      transform: translate3d(-200px, 0px, 0px) !important;
    }

    .btn {
      background-color: transparent;
      width: 10px;
      border: none;
      float: right;
      margin: 0;
      padding: 0;

      &.btn-secondary {
        color: $color-text;
      }

      &:hover {
        color: $color-text;
      }

      &::before {
        background-color: transparent;
        color: $color-text;
      }
    }
  }
}

.forum__profile {
  display: flex;
  align-items: center;

  .forum__profile--avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: auto;
      //   border-radius: 50%;
    }
  }

  .forum__profile--info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      font-size: 10px;
      line-height: 10px;
      color: $color-light-text;
    }
  }
}

.forum-post__title {
  margin: 15px 0;
  font-size: 16px;
  font-weight: 700;
}

.forum-post__text {
  font-size: 14px;
}

.forum-post__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .forum-post__tags {
    display: flex;
    align-items: center;

    .forum-post__tag {
      background-color: $color-light-gray;
      font-size: 12px;
      margin-right: 10px;
      padding: 3px 8px;
      border-radius: 5px;
    }
  }

  .forum-post__statistics {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .forum-post__statistics-item {
      margin-left: 15px;
      font-size: 12px;
      display: flex;
      align-items: center;
      color: $color-text;
      line-height: 12px;

      span {
        display: inline-block;
        margin-left: 3px;
      }
    }

    .post__thumbup {
      margin: -5px 0 5px 10px;
      color: $color-text;
      cursor: pointer;

      &:hover {
        color: $color-primary;
      }

      &.active {
        color: $color-primary;        
      }
    }
  }
}
